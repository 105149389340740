import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { SpaceSwatches, RadiusSwatches } from "../docz-components/Swatches";
import { Flex } from "../components/Flex";
import { space, containerWidths, radii, zIndices } from "./space";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "spacing"
    }}>{`Spacing`}</h1>
    <h2 {...{
      "id": "space"
    }}>{`Space`}</h2>
    <p>{`The space key contains a scale to be used for `}<inlineCode parentName="p">{`margin`}</inlineCode>{` and `}<inlineCode parentName="p">{`padding`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://styled-system.com/api#space"
      }}>{`styled-system props`}</a>{`.`}</p>
    <p>{`The examples use `}<inlineCode parentName="p">{`padding`}</inlineCode>{` in dark to demonstrate the space scale.`}</p>
    <SpaceSwatches space={space} mdxType="SpaceSwatches" />
    <h2 {...{
      "id": "radii"
    }}>{`Radii`}</h2>
    <p>{`The radii key contains a scale to be used for the `}<inlineCode parentName="p">{`borderRadius`}</inlineCode>{` prop.`}</p>
    <RadiusSwatches radii={radii} mdxType="RadiusSwatches" />
    <h2 {...{
      "id": "zindices"
    }}>{`zIndices`}</h2>
    <p>{`To minimize random problems with z-index clashes, please set the z-indexes with the prop shorthand:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`zIndex="farBack"`}</inlineCode>{` - very far in the background, e.g. background images`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`zIndex="back"`}</inlineCode>{` - just behind the normal content`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`zIndex="base"`}</inlineCode>{` - main page content`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`zIndex="front"`}</inlineCode>{` - slightly above the content, e.g. a highlighted Box,...`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`zIndex="nav"`}</inlineCode>{` - navigation bar, etc that should stay visible on scrolling`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`zIndex="overlay"`}</inlineCode>{` - modals, things that block the rest of the app.`}</li>
    </ul>
    <h2 {...{
      "id": "containerwidths"
    }}>{`ContainerWidths`}</h2>
    <p>{`Only to be used with `}<a parentName="p" {...{
        "href": "/components/Container"
      }}>{`Container`}</a>{`. See the docs for the component.`}</p>
    <h2 {...{
      "id": "breakpoints-and-media-queries"
    }}>{`Breakpoints and Media Queries`}</h2>
    <p>{`All styles are defined mobile-first. Meaning the default styles are set for tiny (phone) screens and media queries are used to add specific styles for larger screens.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://styled-system.com/responsive-styles"
      }}>{`styled system docs on responsive styles`}</a>{` explain really well how this works.`}</p>
    <p>{`If you need to access the breakpoints directly, they are available as `}<inlineCode parentName="p">{`theme.breakpoints`}</inlineCode>{` in the theme object.`}</p>
    <p>{`There are three breakpoints:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`theme.breakpoints[0]`}</inlineCode>{` or `}<inlineCode parentName="li">{`theme.breakpoints.small`}</inlineCode>{` - for huge phones or small tablets`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`theme.breakpoints[1]`}</inlineCode>{` or `}<inlineCode parentName="li">{`theme.breakpoints.medium`}</inlineCode>{` - for tablets and smaller desktop windows`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`theme.breakpoints[2]`}</inlineCode>{` or `}<inlineCode parentName="li">{`theme.breakpoints.large`}</inlineCode>{` - for iPad Pros and large desktop windows`}</li>
    </ul>
    <p>{`For convenience, there's also a 'mediaQueries' object in the theme.`}</p>
    <p>{`Use it like:`}</p>
    <pre><code parentName="pre" {...{}}>{`styled.div\`
  \${/* default styles (mobile)*/}
  color: blue;

  \${/* get the media query for medium screens (desktop) and override styles */}

   \${({theme}) => theme.mediaQueries.medium} {
       color: red;
   }

  \${/* you can also use the array index [0,1,2] (below gets 2 / large) */}

   \${({theme}) => theme.mediaQueries[2]} {
       color: red;
   }
\`
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      